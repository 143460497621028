
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

interface IMember {
  username: string;
  name: string;
  slotid: string;
  liveid: string;
  wallet: string;
  createdAt: string;
}

export default defineComponent({
  name: "members",
  components: {
    Datatable,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    const tableHeader = ref([
      {
        name: t("Members.ID"),
        key: "username",
        text: true,
        sortable: true,
      },
      {
        name: t("Members.Wallet"),
        key: "wallet",
        sortable: true,
        currency: true,
      },
      {
        name: t("Members.RegDate"),
        key: "createdAt",
        text: true,
      },
    ]);

    const tableData = ref<Array<IMember>>([]);
    const initMembers = ref<Array<IMember>>([]);
    const search = ref({
      username: "",
    });

    /**
     * Get Members List
     *
     */
    const getMembers = async () => {
      const results = await ApiService.get(`/members`)
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
      initMembers.value.splice(0, results.length, ...results);
    };
    /**
     * Search Member
     *
     */
    const searchMember = (filter: string) => {
      const text = search.value[filter];
      let filtered = initMembers.value;

      if (text !== "")
        filtered = initMembers.value.filter((f) => {
          return String(f[filter]).includes(text);
        });
      tableData.value.splice(0, tableData.value.length, ...filtered);
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.MemberList"), []);
      getMembers();
    });

    return {
      tableData,
      tableHeader,
      getMembers,
      search,
      searchMember,
    };
  },
});
